import { Divider, Paper, TablePagination } from '@mui/material';
import CardCentralNotification from '../CardCentralNotificiation';
import { makeStyles } from '@material-ui/styles';
import useSetPayload from '../../pages/CentralNotification/hooks/useSetPayload';
import useRequestFilter from '../../pages/CentralNotification/hooks/useRequestFilter';
import { useContext, useState } from 'react';
import { LoadingContext } from '../../context/LoadingContext';
import ButtonWithMenu from '../ButtonWithMenu';
import { DownloadNotifications } from '../../lib/Reports/downloadNotifications';

const TableCentralNotification = ({
  data,
  watch,
  setValue,
  setData,
  handleSizeChange,
  handlePageChange,
  setAlertId,
  setModalIsOpen,
  dataNotificacaoFinal,
  dataNotificacaoIni,
  selectedOptionsStatus,
  selectedOptionsNotificationType,
  selectedOptionsStatusLidas,
}) => {
  const [openModalAmostragem, setOpeModalAmostragem] = useState(false);

  const firstRowDisplayed = (data?.page - 1) * data?.count + 1;
  const lastRowDisplayed = Math.min(data?.page * data?.count, data?.totalItems);
  const totalItems = data?.totalItems;

  const stringToModalExportActualList = `${firstRowDisplayed}–${lastRowDisplayed} de ${totalItems}`;

  const useStyles = makeStyles({
    root: {
      overflowX: 'auto',
    },
    pagination: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
      zIndex: 999,
    },
  });

  const classes = useStyles();

  const { setPayload } = useSetPayload({
    watch,
    dataNotificacaoFinal,
    dataNotificacaoIni,
    selectedOptionsStatus,
    selectedOptionsNotificationType,
    selectedOptionsStatusLidas,
  });

  const prepareDownload = async ({ selectedOption, sizeFile }) => {
    setPayload().then(async (payload: any) => {
      if (sizeFile === 'all_list') {
        delete payload.size;
        delete payload.page;
      }

      const file = await DownloadNotifications({
        fileType: selectedOption,
        payload,
      });

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `central-de-notificacoes.${String(selectedOption).toLowerCase()}`
      );
      document.body.appendChild(link);
      link.click();

      setOpeModalAmostragem(false);
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'cenmter',
        }}
      >
        <p style={{ color: '#00000099' }}>
          Exibindo {data?.count ?? 0} notificações
        </p>
        {/* <ButtonWithMenu
          prepareDownload={prepareDownload}
          openModalAmostragem={openModalAmostragem}
          setOpeModalAmostragem={setOpeModalAmostragem}
          stringToModalExportActualList={stringToModalExportActualList}
          totalItems={totalItems}
          title="Exportar Central de Notificações"
        /> */}
      </div>

      <Paper
        variant="outlined"
        sx={{
          marginTop: '25px',
          padding: '32px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '10px',
          gap: '40px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {data?.data?.map((notification, index) => {
          return (
            <>
              <CardCentralNotification
                setModalIsOpen={setModalIsOpen}
                setAlertId={setAlertId}
                status={notification?.type?.name}
                data={notification?.user_notify.created_at}
                namePatient={notification?.notify_data?.pessoa?.nome}
                id={notification.user_notify.id}
                alertId={notification.id}
                viewed_at={notification.user_notify?.viewed_at}
                notificationResolved={notification.is_resolved}
                isResolved={notification.resolved_by}
                resolved_at={notification.resolved_at}
                idPessoa={notification?.notify_data?.pessoa?.id}
                idExam={notification?.notify_data?.hpv_id ?? null}
              />

              {index + 1 !== data?.data?.length && <Divider />}
            </>
          );
        })}

        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data?.totalItems || 0}
          rowsPerPage={watch().size || 25}
          page={data?.page - 1 || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleSizeChange}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
        />
      </Paper>
    </>
  );
};

export default TableCentralNotification;
