import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SampleStorage } from './context/SampleContext';
import AgendaCitizen from './pages/AgendaCitizen';
import Rescheduling from './pages/AgendaCitizen/rescheduling';
import UnfulfilledSchedule from './pages/AgendaCitizen/unfulfilledSchedule';
import { Anamnese } from './pages/Anamnese';
import Convocation from './pages/Convocation';
import Dashboard from './pages/Dashboard/Dashboard';
import { EditRegister } from './pages/EditRegister';
import ExamResult from './pages/ExamResult';
import ForgotPasswordSendEmail from './pages/ForgotPassword/ForgotPasswordSendEmail';
import ForgotPasswordTypeNewPassword from './pages/ForgotPassword/ForgotPasswordTypeNewPassword';
import GetIp from './pages/GetIp';
import Home from './pages/Home/Home';
import { InviteEstablishment } from './pages/InviteEstablishment';
import InviteProfessional from './pages/InviteProfessionals';
import { InviteUser } from './pages/InviteUser';
import ListPatients from './pages/ListPatients/ListPatients';
import ListRequisitions from './pages/ListRequisitions/ListRequisitions';
import ManageAccount from './pages/ManageAccount';
import ManageEstablishments from './pages/ManageEstablishments';
import Panels from './pages/Panels';
import { HPVPanel } from './pages/Panels/hpv';
import PluginPolicyPrivacy from './pages/PluginPolicyPrivacy';
import PolicyPrivacy from './pages/PolicyPrivacy';
import Profile from './pages/Profile/Profile';
import { Exams } from './pages/RegisterExam';
import RegisterPatient from './pages/RegisterPatient/RegisterPatient';
import RegisterResult from './pages/RegisterResult';
import ReportsCitologia from './pages/Reports/ReportsCitologia';

import { ReportsCitologiaReflexo } from './pages/Reports/ReportsCitologiaReflexo';
import { ReportsColposcopia } from './pages/Reports/ReportsColposcopia';
import ReportsConvocatoria from './pages/Reports/ReportsConvocatoria';
import ReportsEstabelecimentos from './pages/Reports/ReportsEstabelecimentos';
import { ReportsHistopatologia } from './pages/Reports/ReportsHistopatologia';
import ReportsHpv from './pages/Reports/ReportsHpv';
import ReportsPacientes from './pages/Reports/ReportsPacientes';
import ReportsProfissionais from './pages/Reports/ReportsProfissionais';
import ReportsTrackingHpv from './pages/Reports/ReportsTrackingHpv';
import Samples from './pages/Samples/Samples';
import { Schedule } from './pages/Schedule';
import { ScheduleSetting } from './pages/ScheduleSetting';
import SeePatient from './pages/SeePatient/SeePatient';
import SignIn from './pages/SignIn/SignIn';
import TermsUse from './pages/TermsUse';
import TrackingHpv from './pages/TrackingHpv';
import { InjuryTreatment } from './pages/Treatment/InjuryTreatment';
import { UserManagement } from './pages/UserManagement/index';
import ValidateAccount from './pages/ValidateAccount/ValidateAccount';
import { ViewExam } from './pages/ViewExam';
import { ReportsLesoesIntraepiteliais } from './pages/Reports/ReportsLesoesIntraepiteliais';
import { ViewInjuryTreatment } from './pages/Treatment/ViewInjuryTreatment';
import { CitologiaReflexoPanel } from './pages/Panels/citologiaReflexo';
import { HistopatologiaPanel } from './pages/Panels/histopatologia';
import { ColposcopiaPanel } from './pages/Panels/colposcopia';
import CentralNotification from './pages/CentralNotification';
import ReportsConsolidado from './pages/Reports/ReportsConsolidado';

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/get-ip" element={<GetIp />} />
        <Route
          path="/esqueceu-senha-envio-email"
          element={<ForgotPasswordSendEmail />}
        />
        <Route
          path="/digite-nova-senha"
          element={<ForgotPasswordTypeNewPassword />}
        />
        {/* agenda */}
        <Route path="/agenda">
          <Route path=":id" element={<AgendaCitizen />} />
        </Route>
        <Route path="/agendamento">
          <Route path=":id" element={<Rescheduling />} />
        </Route>
        <Route
          path="/agendamento_nao_realizado"
          element={<UnfulfilledSchedule />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/agenda-digital" element={<Schedule />} />
        <Route
          path="/configuracao-agenda-digital"
          element={<ScheduleSetting />}
        />
        {/* <Route path="schedule-setting-edit" element={<EditAgenda />} /> */}
        <Route path="/usuarios" element={<UserManagement />} />
        <Route path="/editar-usuario/:id" element={<EditRegister />} />
        <Route path="/gerenciar-conta" element={<ManageAccount />} />
        <Route
          path="gerenciar-estabelecimentos"
          element={<ManageEstablishments />}
        />
        <Route
          path="/cadastrar-estabelecimento"
          element={<InviteEstablishment />}
        />
        <Route path="/paineis" element={<Panels />} />
        <Route path="/paineis/teste-hpv" element={<HPVPanel />} />
        <Route
          path="/paineis/teste-citologia-reflexo"
          element={<CitologiaReflexoPanel />}
        />
        <Route
          path="/paineis/teste-histopatologia"
          element={<HistopatologiaPanel />}
        />
        <Route
          path="/paineis/teste-colposcopia"
          element={<ColposcopiaPanel />}
        />
        <Route path="/cadastrar-usuario" element={<InviteUser />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/convocatoria" element={<Convocation />} />
        <Route path="/profissionais" element={<InviteProfessional />} />
        <Route path="/relatorios/citologia" element={<ReportsCitologia />} />
        <Route
          path="/relatorios/consolidado"
          element={<ReportsConsolidado />}
        />
        <Route
          path="/relatorios/colposcopia"
          element={<ReportsColposcopia />}
        />
        <Route
          path="/relatorios/estabelecimentos"
          element={<ReportsEstabelecimentos />}
        />
        <Route
          path="/relatorios/histopatologia"
          element={<ReportsHistopatologia />}
        />
        <Route path="/relatorios/hpv" element={<ReportsHpv />} />
        <Route
          path="/relatorios/convocatoria"
          element={<ReportsConvocatoria />}
        />
        <Route
          path="/relatorios/rastreamento-hpv"
          element={<ReportsTrackingHpv />}
        />
        <Route path="/relatorios/pacientes" element={<ReportsPacientes />} />
        <Route
          path="/relatorios/profissionais"
          element={<ReportsProfissionais />}
        />
        <Route
          path="/relatorios/citologia-reflexo"
          element={<ReportsCitologiaReflexo />}
        />
        <Route
          path="/relatorios/lesoes-intraepiteliais"
          element={<ReportsLesoesIntraepiteliais />}
        />
        <Route path="/rastreamento">
          <Route path="hpv" element={<TrackingHpv />} />
        </Route>
        <Route path="/pacientes">
          <Route path="" element={<ListPatients />} />
          <Route path=":id" element={<SeePatient />} />
          <Route path=":id/anamnese" element={<Anamnese />} />
          <Route path=":id/exam" element={<Exams />} />
          <Route path=":id/exame/:examId/visualizar" element={<ViewExam />} />
          <Route path=":id/edit" element={<RegisterPatient />} />
          <Route path=":id/result/:examId" element={<ExamResult />} />
          <Route
            path=":id/tratamento-lesoes-intraepiteliais/visualizar"
            element={<ViewInjuryTreatment />}
          />
          <Route
            path=":id/tratamento-lesoes-intraepiteliais"
            element={<InjuryTreatment />}
          />
        </Route>
        <Route path="/cadastrar-paciente" element={<RegisterPatient />} />4
        <Route path="/cadastrar-resultado" element={<RegisterResult />} />
        <Route
          path="/samples"
          element={
            <SampleStorage>
              <Samples />
            </SampleStorage>
          }
        />
        <Route path="/central-notificacao" element={<CentralNotification />} />
        <Route path="/requisitions" element={<ListRequisitions />} />
        <Route path="/validate/account/:token" element={<ValidateAccount />} />
        <Route path="/termos-de-uso" element={<TermsUse />} />
        <Route path="/politica-de-privacidade" element={<PolicyPrivacy />} />
        <Route path="/politicaprivacidade" element={<PluginPolicyPrivacy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Application;
