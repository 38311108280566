import { useEffect } from 'react';
import useStatesFields from '../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../lib/auth';
import api from '../../api';

const useSetFilterByPerfil = ({
  setListFilter,
  setSelectedOptionsLaboratorio,
  setListFilterShow,
  setShowTableData,
  loadReports,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  setOptionsMunicipios,
  setEstadosOptions,
  setOptionsEstabelecimentos,
  setOptionsLaboratorios,
  setOptionsProfisisonaisLaboratorio,
  setValue,
  fieldProfissionalResultado,
  fieldLaboratorioResultado,
  selectedLaboratorio,
  setSelectedLaboratorio,
}: Partial<any>) => {
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    setListFilter((prev) => {
      return prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      );
    });
  };
  const handleSetFilterShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) => {
      const exists = prev.some((item) => item.inputName === inputName);
      return exists
        ? prev
        : [...prev, { inputName: inputName, value: newValue }];
    });
  };

  const userToken = getUserToken();
  const {
    permissao_atual: {
      contexto: contextoPermissao,
      id: idPermissao,
      nome: nomePermissao,
    },
    estabelecimentoId: estabelecimentoIdUser,
    estabelecimento_saude: {
      nome_fantasia: estabelecimentoNameUser,
      endereco: {
        municipio: {
          id: municipioUserId,
          nome: municipioUserName,
          estadoSigla: estadoUserSigla,
        },
      },
    },
    pessoa: { nome: userName },
  } = userToken;

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        sigla: sigla,
      },
    ]);
    handleSetFilter(`Estado: ${sigla}`, 'estado');

    setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios({
      id: municipioId,
      nome: municipioNome,
    });
    handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
    setMunicipiosSelected([municipioId]);
  };

  const setDefaultEstabelecimento = ({
    estabelecimentoId,
    estabelecimentoNome,
  }) => {
    setOptionsEstabelecimentos({
      id: estabelecimentoIdUser,
      municipio: municipioUserName,
      municipio_id: municipioUserId,
      nome: estabelecimentoNameUser,
      label: estabelecimentoNameUser,
    });

    handleSetFilter(
      `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      'estabelecimento_saude'
    );

    setEstabelecimentosSelected([estabelecimentoIdUser]);
  };

  const setDefaultProfissionalResponsavel = ({
    profissionalId,
    profissionalNome,
  }) => {
    const objProf = {
      idprofissional: profissionalId,
      nomeprofissional: profissionalNome,
      label: profissionalNome,
    };

    handleSetFilter(
      `Profissional Responsável Pelo Resultado: ${profissionalNome}`,
      fieldProfissionalResultado
    );
    setOptionsProfisisonaisLaboratorio([objProf]);

    setValue(fieldProfissionalResultado, objProf);
  };

  const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
    const objLab = [
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ];
    setOptionsLaboratorios(objLab);
    setSelectedOptionsLaboratorio(objLab);
    setSelectedLaboratorio(objLab);

    handleSetFilter(
      `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
      fieldLaboratorioResultado
    );
  };

  const isSuperAdmin = nomePermissao === 'SuperAdmin';
  const isEstadual = nomePermissao === 'Admin Estadual';
  const isMunicipal = nomePermissao === 'Admin Municipal';
  const isAdminLaboratorio = nomePermissao === 'Admin Estabelecimento';
  const isPadraoEstabelecimento = contextoPermissao === 'UNIDADE DE SAÚDE';
  const isMedicoLaboratorio = idPermissao === 7;
  const isOutrosLaboratorio = idPermissao === 8;

  const actionsWhenUserIsEstadual = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
  };

  const actionsWhenUserIsMunicipal = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
    setDefaultMunicipio({
      municipioId: String(municipioUserId),
      municipioNome: municipioUserName,
    });

    handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    handleSetFilterShow(`Município: ${municipioUserName}`, 'municipio');

    setShowTableData(true);
  };

  const actionsWhenUserIsAdminLaboratorio = () => {
    setDefaultEstado({
      sigla: userToken.estabelecimento_saude.endereco.municipio.estado.sigla,
    });

    setDefaultLaboratorio({
      laboratorioId: userToken.estabelecimentoId,
      laboratorioName: userToken.estabelecimento_saude.nome_fantasia,
    });
  };

  const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
    actionsWhenUserIsMunicipal();

    setDefaultEstabelecimento({
      estabelecimentoId: estabelecimentoIdUser,
      estabelecimentoNome: estabelecimentoNameUser,
    });

    handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    handleSetFilterShow(`Município: ${municipioUserName}`, 'municipio');
    handleSetFilterShow(
      `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      'estabelecimento_saude'
    );
  };
  const actionsWhenUserIsMedicoLaboratorio = async () => {
    actionsWhenUserIsAdminLaboratorio();

    setShowTableData(false);

    const profissional = await api.get('/profissionais', {
      params: {
        cpf: userToken.pessoa.cpf,
      },
    });

    const profissionalId = profissional?.data?.data?.[0]?.id;

    // loadReports({
    //   payload: {
    //     estados: estadoUserSigla,

    //     laboratorioLaudoId: estabelecimentoIdUser,
    //     profissionalResultadoId: profissionalId,
    //     page: 1,
    //     size: 25,
    //   },
    // });

    setDefaultProfissionalResponsavel({
      profissionalId: profissionalId,
      profissionalNome: userToken.pessoa.nome,
    });

    handleSetFilter(
      `Profissional Responsável Pelo Resultado: ${userName}`,
      fieldProfissionalResultado
    );

    // handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    // handleSetFilterShow(
    //   `Laboratório Responsável Pelo Laudo: ${estabelecimentoNameUser}`,
    //   'laboratorioId'
    // );
    // handleSetFilterShow(
    //   `Profissional Responsável Pelo Resultado: ${userName}`,
    //   'profissionalLaudoId'
    // );
  };

  const loadFiltersByPerfil = () => {
    if (isSuperAdmin) {
      return;
    }

    if (isEstadual) {
      actionsWhenUserIsEstadual();
    }

    if (isMunicipal) {
      actionsWhenUserIsMunicipal();

      loadReports({
        payload: {
          estado: estadoUserSigla,
          municipios: municipioUserId,
          page: 1,
          size: 25,
        },
      });
    }

    if (isAdminLaboratorio || isOutrosLaboratorio) {
      actionsWhenUserIsAdminLaboratorio();
    }

    if (isPadraoEstabelecimento) {
      actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();

      loadReports({
        payload: {
          estado: estadoUserSigla,
          municipios: municipioUserId,
          estabelecimento: estabelecimentoIdUser,
          tipo: 'Reflexo',
          page: 1,
          size: 25,
        },
      });
    }

    if (isMedicoLaboratorio) {
      actionsWhenUserIsMedicoLaboratorio();
    }
  };

  return {
    loadFiltersByPerfil,
  };
};

export default useSetFilterByPerfil;
