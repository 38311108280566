import moment from 'moment';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import { getEstabelecimentos } from '../../../../../lib/getEstabelecimentos';
interface HandleChangesFiltersProps {
  setCidades: any;
  setEstabelecimentos: any;
  setValue: any;
  setListFilter: any;
  setListFilterShow: any;
  estados: any[];
  listFilter: any[];
  watch: (name: string) => any;
  coletaProcedures: Array<{ value: string; label: string }>;
  resultadoProcedures: Array<{ value: string; label: string }>;
}

const useHandleChangesFilters = ({
  setCidades,
  setEstabelecimentos,
  setValue,
  setListFilter,
  setListFilterShow,
  estados,
  listFilter,
  watch,
  coletaProcedures,
  resultadoProcedures,
}: HandleChangesFiltersProps) => {
  const handleSetFilter = (
    newValue: any,
    inputName: string,
    fieldLabel?: string
  ) => {
    if (!inputName) {
      return;
    }

    if (newValue == null || newValue === '') {
      setListFilter((prev) =>
        prev.map((item) =>
          item.inputName === inputName ? { ...item, value: '' } : item
        )
      );
      setListFilterShow((prev) =>
        prev.filter((f) => f.inputName !== inputName)
      );
      return;
    }

    const currentFilter = listFilter.find(
      (item) => item.inputName === inputName
    );

    let formattedValue = newValue;

    if (Array.isArray(newValue)) {
      const validValues = Array.from(new Set(newValue)).filter(Boolean);
      formattedValue = validValues.length > 1 ? 'Vários' : validValues[0];
    }

    formattedValue = String(formattedValue || '');

    let displayValue = formattedValue;
    if (fieldLabel && !formattedValue.includes(fieldLabel)) {
      displayValue = `${fieldLabel}: ${formattedValue}`;
    }

    if (currentFilter?.value === displayValue) {
      return;
    }

    let finalValue = displayValue;

    if (inputName.includes('dt_')) {
      const isProcedimentoColeta = inputName.includes('coleta');
      const procedimentoValue = isProcedimentoColeta
        ? watch('procedimento_coleta')
        : watch('procedimento_resultado');

      if (procedimentoValue) {
        const procedures = isProcedimentoColeta
          ? coletaProcedures
          : resultadoProcedures;
        const procedureOption = procedures.find(
          (p) => p.value === procedimentoValue
        );

        if (procedureOption) {
          const dateLabel = formattedValue.includes(procedureOption.label)
            ? formattedValue
            : `${procedureOption.label} - Data da ${
                isProcedimentoColeta ? 'Coleta' : 'Resultado'
              }: ${formattedValue}`;
          finalValue = dateLabel;
        }
      }
    }

    setListFilter((prev: any[]) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: finalValue } : item
      )
    );

    if (finalValue && finalValue !== '') {
      setListFilterShow((prev: any[]) => {
        const filtered = prev.filter((f) => f.inputName !== inputName);
        return [...filtered, { inputName, value: finalValue }];
      });
    } else {
      setListFilterShow((prev: any[]) =>
        prev.filter((f) => f.inputName !== inputName)
      );
    }
  };

  const formatMultiSelectValue = (
    values: string[],
    fieldLabel: string
  ): string => {
    if (!values || values.length === 0) return '';
    return `${fieldLabel}: ${values.length > 1 ? 'Vários' : values[0]}`;
  };

  const getCidadesEstabelecimentos = async (sigla) => {
    try {
      const response = await getCidades(sigla);
      setCidades(response);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  };

  const getEstabelecimento = async ({ municipio }) => {
    try {
      const response = await getEstabelecimentos({
        municipio,
      });
      setEstabelecimentos(response.data);
    } catch (error) {
      console.error('Erro ao buscar estabelecimentos:', error);
    }
  };

  const handleChangeUf = ({ newValue, onChange }) => {
    onChange(newValue);
    if (newValue) {
      const estado = estados.find((item) => item.nome === newValue);
      if (estado) {
        getCidadesEstabelecimentos(estado.sigla);
      }
      handleSetFilter(newValue, 'estado', 'Estado');
    } else {
      handleSetFilter('', 'estado');
    }
  };

  const handleChangeCidade = ({ newValue, onChange }) => {
    onChange(newValue);
    if (newValue) {
      getEstabelecimento({ municipio: newValue });
      handleSetFilter(newValue, 'municipio', 'Município');
    } else {
      handleSetFilter('', 'municipio');
    }
  };

  const handleChangeEstabelecimento = ({ newValue, onChange }) => {
    if (newValue) {
      onChange(newValue);
      handleSetFilter(
        newValue.nome_fantasia,
        'estabelecimento_saude',
        'Estabelecimento'
      );
    } else {
      setValue('estabelecimento_saude', null);
      handleSetFilter('', 'estabelecimento_saude');
    }
  };

  const handleChangeRealizouAtendimento = (
    newValue,
    setShowMotivoNaoAtendimento,
    onChange
  ) => {
    onChange(newValue);

    if (!newValue) {
      setShowMotivoNaoAtendimento(false);
      setValue('atendimento_status', null);
      handleSetFilter('', 'atendimento_status');
      handleSetFilter('', 'realizou_atendimento');
      return;
    }

    const isRealizadoAtendimento = newValue === 'Realizado';
    setShowMotivoNaoAtendimento(!isRealizadoAtendimento);

    if (isRealizadoAtendimento) {
      setValue('atendimento_status', 'REALIZADO');
      handleSetFilter(
        'REALIZADO',
        'atendimento_status',
        'Status do Atendimento'
      );
      handleSetFilter(
        'Realizado',
        'realizou_atendimento',
        'Realizou Atendimento'
      );
    } else {
      setValue('atendimento_status', '');
      handleSetFilter(
        'Não realizado',
        'realizou_atendimento',
        'Realizou Atendimento'
      );
    }
  };

  const handleChangeDatePicker = ({
    date,
    onChange,
    datePickerName,
    label,
  }) => {
    const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;
    onChange(date);

    if (formattedDate) {
      const isProcedimentoColeta = datePickerName.includes('coleta');
      const procedimentoValue = isProcedimentoColeta
        ? watch('procedimento_coleta')
        : watch('procedimento_resultado');

      if (procedimentoValue) {
        const procedures = isProcedimentoColeta
          ? coletaProcedures
          : resultadoProcedures;
        const procedureOption = procedures.find(
          (p) => p.value === procedimentoValue
        );

        if (procedureOption) {
          const displayText = `${procedureOption.label} - ${
            label ? `${label} ` : ''
          }${formattedDate}`;
          handleSetFilter(displayText, datePickerName);
        }
      }
    } else {
      handleSetFilter('', datePickerName);
    }
  };

  return {
    handleSetFilter,
    handleChangeUf,
    handleChangeCidade,
    handleChangeEstabelecimento,
    handleChangeRealizouAtendimento,
    handleChangeDatePicker,
    formatMultiSelectValue,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  };
};

export default useHandleChangesFilters;
