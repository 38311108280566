/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//src/pages/RegisterPatient/RegisterPatient.tsx

import { yupResolver } from '@hookform/resolvers/yup';
import Add from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { toast } from 'react-toastify';

import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import ReturnButton from '../../components/ReturnButton';
import { SideMenu } from '../../components/SideMenu';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { useStyles } from './InviteProfessionals.style';
import { postProfissionais, putProfissionais } from '../../lib/profissionais';
import { returnCpfValidation } from '../../utils/validateCPF';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { getUserToken } from '../../lib/auth';
import { schema } from './schema';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingContext } from '../../context/LoadingContext';

interface Inputs {
  cns: string;
  cbo: string;
  tipo: string;
  nome: string;
  cpf: string;
  estabelecimento_saude: any;
  uf: any;
  email: string;
  municipio: any;
  ine: string;
  nome_equipe: string;
  area: string;
  micro_area: string;
  idProfessional: string;
  estabelecimento_equipe: {
    id: number;
    razao_social: string;
    nome_fantasia: string;
    cnes: string;
    inativo: boolean;
  } | null;
  endereco: {
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    referencia: string;
    zona: string;
    bairro: string;
    municipio: {
      id: number;
      nome: string;
      estado: {
        id: number;
        sigla: string;
        nome: string;
      };
    };
  };
}

export default function InviteProfessional() {
  const styles = useStyles();
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [isPost, setIsPost] = React.useState(true);
  const [haventVinculo, sethaventVinculo] = React.useState(false);
  const [idEquipe, setIdEquipe] = React.useState();

  const [disableInputsVinculo, setDisableInputsVinculo] =
    React.useState(haventVinculo);

  const [openDialogCancel, setOpenDialogCancel] = React.useState(false);
  const [hasEstabelecimentos, setHasEstabelecimentos] = React.useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm<Inputs>({
    resolver: yupResolver(schema(haventVinculo, hasEstabelecimentos)),
  });

  React.useEffect(() => {
    if (watch('estabelecimento_saude')?.length > 1) {
      setHasEstabelecimentos(true);
    } else {
      setHasEstabelecimentos(false);
    }
  }, [watch('estabelecimento_saude')]);

  const [estados, setEstados] = React.useState<Array<any>>([]);

  const [cidadesEstabelecimentos, setCidadesEstabelecimentos] =
    React.useState<any>([]);
  const [estabelecimentosSelect, setEstabelecimentosSelect] =
    React.useState<any>([]);

  const [loading, setLoading] = React.useState(false);
  const [loadingMunicipio, setLoadingMunicipio] = React.useState(false);
  const [loadingEstabelecimento, setLoadingEstabelecimento] =
    React.useState(false);

  const { setLoading: setLoadingContext } = React.useContext(LoadingContext);

  async function load() {
    await api
      .get('/estados')
      .then((response) => {
        setEstados(response.data);
      })
      .catch((err) => {
        console.error('ops! ocorreu um erro' + err);
      });
  }

  const getEstabelecimentosByMunicipio = async (municipio, index) => {
    const clearedEstabelecimentoOffSelectedRow = estabelecimentosSelect.filter(
      (cidade) => cidade.index !== index
    );
    setLoadingEstabelecimento(true);
    setEstabelecimentosSelect(clearedEstabelecimentoOffSelectedRow);
    try {
      const response = await api.get('/estabelecimentos-saude/unpaged', {
        params: {
          municipio: municipio,
        },
      });

      setEstabelecimentosSelect((prev) => [
        ...prev,
        { index: index, arr: response.data.data },
      ]);
    } catch (error) {}
    setLoadingEstabelecimento(false);
  };

  async function getCidadesEstabelecimentos(sigla: string, index) {
    setLoadingMunicipio(true);
    const clearedMunicipioOffSelectedRow = cidadesEstabelecimentos.filter(
      (cidade) => cidade.index !== index
    );
    setCidadesEstabelecimentos(clearedMunicipioOffSelectedRow);
    try {
      const response = await api.get(`/municipios?sigla=${sigla}`, {
        params: { showAll: true },
      });

      setCidadesEstabelecimentos((prev) => [
        ...prev,
        { index: index, arr: response.data },
      ]);
    } catch (error) {}

    setLoadingMunicipio(false);
  }

  const onSubmit = async (data) => {
    const arrayFieldEstabelecimento = data.estabelecimento_saude.map((item) => {
      return { id: item.id };
    });

    const filterFirstEstabelecimento = () => {
      if (arrayFieldEstabelecimento[0].id === 0) {
        const newArr = arrayFieldEstabelecimento.filter(
          (_, index) => index > 0
        );

        return newArr;
      } else {
        return arrayFieldEstabelecimento;
      }
    };

    const payload = {
      cnes: data.estabelecimento_saude[0].cnes,
      cbo: data.cbo,
      cargo: data.tipo,
      cns: data.cns,
      cpf: data.cpf.replace(/\D/g, ''),
      nome: data.nome,
      estabelecimentos_saude: filterFirstEstabelecimento(),

      email: data.email,
      equipe: haventVinculo
        ? null
        : {
            ine: data.ine,
            nome: data.nome_equipe,
            area: data.area,
            microArea: data.micro_area,
            estabelecimentoId: data.estabelecimento_equipe.id,
          },
    };

    if (isPost) {
      const responseProfessionalRoute = await postProfissionais(payload);
      if (responseProfessionalRoute.status === 201) {
        toast.success('Profissional Cadastrado com Sucesso.', {
          position: 'bottom-right',
        });
        navigate('/home');
      } else {
      }
      setLoading(false);
    } else {
      const responseProfessionalRoute = await putProfissionais(
        payload,
        data.idProfessional
      );
      if (responseProfessionalRoute.status === 200) {
        toast.success('Profissional Editado com Sucesso.', {
          position: 'bottom-right',
        });

        navigate('/home');
      }
      setLoading(false);
    }

    if (haventVinculo && idEquipe) {
      await api.delete(`/profissional_equipe/${idEquipe}`);
    }
  };

  const getCbo = async () => {
    const response = await api.get(`/cbo/codigo/${watch('cbo')}`);

    if (response?.data) {
      setValue('tipo', response?.data?.descricao);
    } else {
      setValue('tipo', '');
    }
  };

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate('/home');
  };

  const CPF = watch('cpf');

  const validateCpfWhenUserTypeElevenNumbers = () => {
    const cpfIsValid = returnCpfValidation(getValues);

    const CpfWithoutChar = CPF.replace(/[-.]/g, '');

    const setOrClearErrorsOnCpfInput = () => {
      cpfIsValid === false
        ? setError('cpf', { type: 'manual', message: 'CPF inválido' })
        : clearErrors('cpf');
    };

    if (CpfWithoutChar.length === 11) setOrClearErrorsOnCpfInput();
  };

  const getProfessional = async () => {
    setLoadingContext(true);
    try {
      if (watch('cpf') || watch('cns')) {
        const response = await api.get('/profissionais', {
          params: {
            cpf: watch('cpf')?.replace(/[^0-9\s]+/g, ''),
            cns: watch('cns'),
          },
        });

        if (response.data.data[0]) {
          setIsPost(false);

          const email =
            response.data.data[0].pessoa.contatos.find(
              (item) => item.forma_contato === 'EMAIL'
            )?.descricao ||
            response.data.data[0].pessoa.usuario?.email ||
            '';

          setValue('idProfessional', response.data.data[0].id);
          setValue('cpf', response.data.data[0].pessoa.cpf);
          setValue('nome', response.data.data[0].pessoa.nome);
          setValue(
            'cns',
            response.data.data[0].cns || response.data.data[0].pessoa.cns
          );
          setValue('email', response.data.data[0].pessoa?.email);
          setValue('cbo', response.data.data[0].cbo);
          setValue('tipo', response.data.data[0].cargo);
          setValue('email', email);
          setValue('uf', [
            ...response.data.data[0].estabelecimentos?.map(
              (estabelecimento) => {
                return estabelecimento.estabelecimento.endereco.municipio
                  .estadoSigla;
              }
            ),
          ]);
          setValue('municipio', [
            ...response.data.data[0].estabelecimentos?.map(
              (estabelecimento) => {
                return estabelecimento.estabelecimento.endereco.municipio;
              }
            ),
          ]);

          if (response.data.data[0].estabelecimentos.length > 0) {
            estabelecimentos.splice(0, estabelecimentos.length);

            remove(0);

            // while (estabelecimentos.length > 0) {
            //   remove(0);
            // }

            response.data.data[0].estabelecimentos.forEach((item) => {
              const hasThisEstablishmentInArrayEstablishments = !!watch(
                'estabelecimento_saude'
              )?.find(
                (itemField) =>
                  itemField.nome_fantasia === item.estabelecimento.nome_fantasia
              );

              if (hasThisEstablishmentInArrayEstablishments) {
                return;
              } else {
                append({
                  cnes: item.estabelecimento.cnes,
                  id: item.estabelecimento.id,
                  inativo: item.estabelecimento.inativo,
                  nome_fantasia: item.estabelecimento.nome_fantasia,
                  razao_social: item.estabelecimento.razao_social,
                });
              }
            });
          }

          const responseBind = await api.get(
            `/profissional_equipe/pessoa/${response.data.data[0].id}`
          );

          setValue('ine', responseBind.data[0].equipe.ine);
          setValue('nome_equipe', responseBind.data[0].equipe.nome);
          setValue('area', responseBind.data[0].area);
          setValue('micro_area', responseBind.data[0].micro_area);
          setValue(
            'estabelecimento_equipe',
            responseBind.data[0].equipe.estabelecimentoSaude
          );
          setIdEquipe(responseBind.data[0].id);
        } else {
          setValue('idProfessional', '');
          setValue('nome', '');
          setValue('email', '');
          setValue('cbo', '');
          setValue('tipo', '');
          setValue('email', '');
          setValue('ine', '');
          setValue('nome_equipe', '');
          setValue('area', '');
          setValue('micro_area', '');
          setValue('estabelecimento_equipe', null);
          setIsPost(true);
        }
      }
    } catch (error) {}

    setLoadingContext(false);
  };

  const {
    fields: estabelecimentos,
    append,
    remove,
  } = useFieldArray({ name: 'estabelecimento_saude', control });

  const addEstabelecimento = () => {
    append({
      id: 0,
      razao_social: '',
      nome_fantasia: '',
      cnes: '',
      inativo: true,
    });
  };

  React.useEffect(() => {
    if (estabelecimentos.length === 0) {
      addEstabelecimento();
    }
  }, [estabelecimentos, append]);

  React.useEffect(() => {
    if (CPF) {
      validateCpfWhenUserTypeElevenNumbers();
    } else {
      clearErrors('cpf');
    }
  }, [CPF]);

  React.useEffect(() => {
    load();
  }, []);

  const hasValueInFirstEstablishment = watch('estabelecimento_saude')?.[0]?.id;
  const listOfEstablishments = watch('estabelecimento_saude');

  React.useEffect(() => {
    if (hasValueInFirstEstablishment && !haventVinculo) {
      setValue('estabelecimento_equipe', watch('estabelecimento_saude')[0]);
    }
    if (listOfEstablishments?.length > 1 || haventVinculo) {
      setValue('estabelecimento_equipe', {
        id: 0,
        razao_social: '',
        nome_fantasia: '',
        cnes: '',
        inativo: false,
      });
    }
  }, [hasValueInFirstEstablishment, listOfEstablishments]);

  return (
    <SideMenu>
      <Container sx={{ marginTop: '64px' }}>
        <Box sx={{ width: '100%' }}>
          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>
              {id ? 'Cancelar Edição' : 'Cancelar Cadastro'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {id
                  ? 'Deseja realmente cancelar a edição do profissional ? '
                  : 'Deseja realmente cancelar o cadastro de profissional ?'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCloseConfirmCancel} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Cadastro de Profissional
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link
                  key="1"
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography key="3" color="text.primary">
                  Cadastro de Profissionais de Saúde
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
            <i>* Resposta obrigatória</i>
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ width: '100%', marginTop: '24px' }}>
              <Paper
                elevation={0}
                sx={{
                  width: '100%',
                  border: `1px solid RGBA(0, 0, 0, 0.23)`,
                  borderRadius: '10px',
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  style={{ margin: '25px', paddingTop: '10px' }}
                >
                  Informações do Profissional
                </Typography>
                <Divider
                  variant="middle"
                  sx={{ width: '96%', margin: '0 auto' }}
                />
                <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            required
                            onChange={(e) => {
                              const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                              onChange(cpf);
                            }}
                            value={value ? value : ''}
                            label="CPF do Profissional"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            error={!!errors.cpf}
                            helperText={errors.cpf?.message}
                            sx={{ width: '100%' }}
                            InputProps={{
                              autoComplete: 'off',
                              inputComponent: MaskedInput as any,
                              inputProps: {
                                mask: [
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  '.',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  '.',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  '-',
                                  /\d/,
                                  /\d/,
                                ],
                              } as MaskedInputProps,
                            }}
                          />
                        )}
                        name="cpf"
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            onChange={onChange}
                            value={value ? value : ''}
                            label="CNS do Profissional"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 15 }}
                            variant="outlined"
                            required
                            className={styles.textField}
                            error={!!errors.cns}
                            helperText={errors.cns?.message}
                            fullWidth
                          />
                        )}
                        name="cns"
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => getProfessional()}
                      >
                        PESQUISAR
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            fullWidth
                            id="nome"
                            label="Nome completo"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            error={!!errors.nome}
                            helperText={errors.nome?.message}
                            required
                          />
                        )}
                        name="nome"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            onChange={onChange}
                            value={value ? value.trim() : ''}
                            label="Email do Profissional"
                            size="small"
                            variant="outlined"
                            required
                            className={styles.textField}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            fullWidth
                          />
                        )}
                        name="email"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="cbo"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <StyledAsteriskTextField
                              onChange={onChange}
                              label="CBO do Profissional"
                              fullWidth
                              size="small"
                              value={value ? value : ''}
                              sx={{
                                '.MuiOutlinedInput-root': {
                                  padding: 0,
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    onClick={() => getCbo()}
                                    sx={{
                                      marginRight: '1px',
                                      backgroundColor: 'white',
                                      zIndex: 1,
                                    }}
                                  >
                                    <SearchIcon sx={{ color: 'gray' }} />
                                  </Button>
                                ),
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="tipo"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <StyledAsteriskTextField
                              onChange={onChange}
                              label="Tipo de Profissional"
                              fullWidth
                              size="small"
                              value={value ? value : ''}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={10} />
                    <Grid item xs={12} sm={4} md={2}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setValue('idProfessional', '');
                          setValue('cpf', '');
                          setValue('cns', '');
                          setValue('nome', '');
                          setValue('email', '');
                          setValue('cbo', '');
                          setValue('tipo', '');
                          setValue('email', '');
                        }}
                      >
                        LIMPAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box sx={{ width: '100%', marginTop: '24px' }}>
              <Paper
                elevation={0}
                sx={{
                  width: '100%',
                  border: `1px solid RGBA(0, 0, 0, 0.23)`,
                  borderRadius: '10px',
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ padding: '24px 0 24px 24px', gap: 4 }}
                >
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold">
                      Estabelecimento Vinculado
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={addEstabelecimento}
                      style={{
                        backgroundColor: '#238884',
                        color: '#FFFFFF',
                        marginRight: '25px',
                      }}
                    >
                      Adicionar Estabelecimento
                    </Button>
                  </Grid>
                </Grid>
                <Divider
                  variant="middle"
                  sx={{ width: '96%', margin: '0 auto' }}
                />
                <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                  <Grid container>
                    <Grid
                      container
                      // key={estabelecimento.id}
                      spacing={2}
                      my={1}
                    >
                      {estabelecimentos.length > 0
                        ? estabelecimentos?.map((estabelecimento, index) => {
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={estabelecimento.id}
                              >
                                <Grid item xs={12} md={4}>
                                  <Controller
                                    control={control}
                                    name={`uf.${index}`}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <StyledAsteriskTextField
                                          label="UF"
                                          fullWidth
                                          value={value ? value : ''}
                                          size="small"
                                          select
                                          variant="outlined"
                                          className={styles.textField}
                                          onChange={(e) => {
                                            onChange(e.target.value);
                                            getCidadesEstabelecimentos(
                                              e.target.value,
                                              index
                                            );
                                          }}
                                          // defaultValue={() => {
                                          //   const estado =
                                          //     location?.state
                                          //       ?.estabelecimento_saude
                                          //       ?.endereco?.municipio
                                          //       ?.estadoSigla ||
                                          //     (!location?.state &&
                                          //       !location.state &&
                                          //       getUserToken().endereco
                                          //         ?.estado) ||
                                          //     '';

                                          //   estado &&
                                          //     getCidadesEstabelecimentos(
                                          //       estado || ''
                                          //     );

                                          //   return estado;
                                          // }}
                                          error={!!errors?.uf?.[index]}
                                          helperText={
                                            errors?.uf?.[index]?.message
                                          }
                                        >
                                          {estados.map((estado, index) => {
                                            return (
                                              <MenuItem
                                                value={estado?.sigla}
                                                key={index}
                                              >
                                                {estado?.sigla}
                                              </MenuItem>
                                            );
                                          })}
                                        </StyledAsteriskTextField>
                                      );
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <Controller
                                    control={control}
                                    name={`municipio.${index}`}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      const optionsThisInput =
                                        cidadesEstabelecimentos.find(
                                          (cidadeInArr) =>
                                            cidadeInArr.index === index
                                        )?.arr;
                                      return (
                                        <Autocomplete
                                          options={optionsThisInput ?? []}
                                          getOptionLabel={(option) =>
                                            option.nome
                                          }
                                          onChange={(e, newValue) => {
                                            onChange(newValue);
                                            getEstabelecimentosByMunicipio(
                                              newValue?.nome,
                                              index
                                            );
                                          }}
                                          noOptionsText={
                                            loadingMunicipio ? (
                                              <span
                                                className="loader"
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                  margin: '15px 0px 15px 50%',
                                                }}
                                              ></span>
                                            ) : (
                                              'Não há opções'
                                            )
                                          }
                                          value={value ? value : undefined}
                                          renderInput={(params) => (
                                            <StyledAsteriskTextField
                                              {...params}
                                              fullWidth
                                              label="Município"
                                              size="small"
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <Controller
                                    name={`estabelecimento_saude.${index}`}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      const optionsThisInput =
                                        estabelecimentosSelect.find(
                                          (cidadeInArr) =>
                                            cidadeInArr.index === index
                                        )?.arr;
                                      return (
                                        <>
                                          <Autocomplete
                                            options={optionsThisInput ?? []}
                                            getOptionLabel={(option) => {
                                              return option?.nome_fantasia;
                                            }}
                                            onChange={(e, newValue) => {
                                              onChange(newValue);
                                            }}
                                            renderOption={(props, item) => (
                                              <li {...props} key={item.id}>
                                                {item.nome_fantasia}
                                              </li>
                                            )}
                                            noOptionsText={
                                              loadingEstabelecimento ? (
                                                <span
                                                  className="loader"
                                                  style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    margin:
                                                      '15px 0px 15px 150px',
                                                  }}
                                                ></span>
                                              ) : (
                                                'Não há opções'
                                              )
                                            }
                                            value={value ? value : undefined}
                                            renderInput={(params) => (
                                              <StyledAsteriskTextField
                                                {...params}
                                                fullWidth
                                                onChange={(e) => {}}
                                                label="Estabelecimento de Saúde"
                                                size="small"
                                                error={
                                                  !!errors
                                                    ?.estabelecimento_saude?.[
                                                    index
                                                  ]?.nome_fantasia
                                                }
                                                helperText={
                                                  errors
                                                    .estabelecimento_saude?.[
                                                    index
                                                  ]?.nome_fantasia.message
                                                }
                                              />
                                            )}
                                          />
                                        </>
                                      );
                                    }}
                                  />
                                </Grid>

                                {estabelecimentos.length > 1 && (
                                  <Grid item xs={6} md={1.5}>
                                    <Button
                                      fullWidth
                                      startIcon={<ClearIcon />}
                                      onClick={() => {
                                        remove(index);
                                      }}
                                      style={{
                                        color: '#e31b0c',
                                        backgroundColor: 'white',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Remover
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })
                        : undefined}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box sx={{ width: '100%', marginTop: '24px' }}>
              <Paper
                elevation={0}
                sx={{
                  width: '100%',
                  border: `1px solid RGBA(0, 0, 0, 0.23)`,
                  borderRadius: '10px',
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  style={{
                    margin: '25px',
                    paddingTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Equipe Vinculada
                  <FormControlLabel
                    control={
                      <Radio
                        className={styles.radioButton}
                        checked={haventVinculo}
                        onClick={() => {
                          sethaventVinculo(!haventVinculo);
                          setDisableInputsVinculo(!haventVinculo);

                          setValue('ine', '');
                          setValue('nome_equipe', '');
                          setValue('area', '');
                          setValue('micro_area', '');
                          setValue('estabelecimento_equipe', {
                            id: 0,
                            nome_fantasia: '',
                            cnes: '',
                            inativo: false,
                            razao_social: '',
                          });

                          clearErrors('ine');
                          clearErrors('nome_equipe');
                          clearErrors('area');
                          clearErrors('micro_area');
                          clearErrors('estabelecimento_equipe');
                        }}
                      />
                    }
                    label="Não possui vinculação"
                  />
                </Typography>
                <Divider
                  variant="middle"
                  sx={{ width: '96%', margin: '0 auto' }}
                />
                <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={3}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            required={!haventVinculo}
                            onChange={(e) => {
                              const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                              onChange(cpf);
                            }}
                            value={value ? value : ''}
                            label="INE da Equipe"
                            size="small"
                            disabled={disableInputsVinculo}
                            variant="outlined"
                            className={styles.textField}
                            error={!!errors.ine}
                            helperText={errors.ine?.message}
                            sx={{ width: '100%' }}
                          />
                        )}
                        name="ine"
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={9}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            fullWidth
                            id="nome"
                            label="Nome da Equipe"
                            size="small"
                            disabled={disableInputsVinculo}
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            error={!!errors.nome}
                            helperText={errors.nome?.message}
                            required={!haventVinculo}
                          />
                        )}
                        name="nome_equipe"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            fullWidth
                            id="nome"
                            label="Área"
                            size="small"
                            disabled={disableInputsVinculo}
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            error={!!errors.nome}
                            helperText={errors.nome?.message}
                            required={!haventVinculo}
                          />
                        )}
                        name="area"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            fullWidth
                            label="Micro Área"
                            size="small"
                            disabled={disableInputsVinculo}
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            error={!!errors.micro_area}
                            helperText={errors.micro_area?.message}
                            required={!haventVinculo}
                          />
                        )}
                        name="micro_area"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="estabelecimento_equipe"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Autocomplete
                              placeholder="Estabelecimento da Equipe"
                              value={value ? value : null}
                              onChange={(_, newValue) => {
                                onChange(newValue ? newValue : null);
                              }}
                              disabled={disableInputsVinculo}
                              options={watch('estabelecimento_saude')?.filter(
                                (item) => item?.nome_fantasia !== ''
                              )}
                              getOptionLabel={(option) => option?.nome_fantasia}
                              renderInput={(params) => (
                                <StyledAsteriskTextField
                                  {...params}
                                  required={!haventVinculo}
                                  fullWidth
                                  disabled={disableInputsVinculo}
                                  size="small"
                                  label="Estabelecimento da Equipe"
                                  variant="outlined"
                                  className={styles.textField}
                                  error={
                                    !!errors?.estabelecimento_equipe
                                      ?.nome_fantasia
                                  }
                                  helperText={
                                    errors.estabelecimento_equipe?.nome_fantasia
                                      ?.message
                                  }
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
              }}
              mb={3}
              mt={1}
            >
              <Button
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#17bebb',
                }}
                variant="contained"
                type="submit"
                disabled={loading}
                startIcon={<CheckIcon />}
              >
                {id ? 'Salvar' : loading ? 'Cadastrando' : 'Cadastrar'}
              </Button>

              <Button
                sx={{ marginTop: '10px' }}
                variant="outlined"
                color="error"
                onClick={handleClickOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </SideMenu>
  );
}
