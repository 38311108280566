import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsConsolidado } from '../../../../../lib/Reports/getReportsConsolidado';

const useRequestFilter = ({ setTableData }) => {
  const { setLoading } = useContext(LoadingContext);

  const loadReportsConsolidado = async (payload) => {
    try {
      setLoading(true);
      const response = await getReportsConsolidado({ payload });
      setTableData(response);
    } catch (error) {
      console.error('Erro ao carregar relatórios:', error);
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };

  return { loadReportsConsolidado };
};

export default useRequestFilter;
